import React from 'react'
import styles from './ControlPanel.module.css'
import TableRow from './tableRow'
import { useSelector } from "react-redux";

function ActiveTable() {
  const clients = useSelector(({ user: clients }) => clients);
    console.log(clients);
  return (
      <table className={styles.activeTable}>
          <thead>
              <tr>
                  <th>Medarbetare</th>
                  <th>ID</th>
                  <th>Dagar kvar</th>
                  <th>Signaler</th>
                {/*<th>Behandlingar</th>*/}
              </tr>
          </thead>
          <tbody>
              <TableRow {...clients} />
          </tbody>
      </table>
  );
}

export default ActiveTable
