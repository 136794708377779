import React from 'react'
//import green from '../../svg/Green.svg'
import red from '../../svg/Red.svg'
//import yellow from '../../svg/Yellow.svg'
import styles from './ControlPanel.module.css'
import signalUnderPpmLimit from '../../images/signal-underPpmLimit.png'
import signalMissedScheduleItem from '../../images/signal-missedScheduleItem.png'
import signalAbovePpmLimit from '../../images/signal-abovePpmLimit.png'
import { useHistory } from "react-router-dom";
// import { dispatchUserId } from '../../redux/patientSlice';

const Signals = ({ sig }) => {
    let symbol;

    switch (sig) {
        case "underPpmLimit":
            //symbol = green;
            symbol = signalUnderPpmLimit;
            break;
        case "abovePpmLimit":
            //symbol = red;
            symbol = signalAbovePpmLimit;
            break;
        case "missedScheduleItem":
            //symbol = yellow;
            symbol = signalMissedScheduleItem;
            break;
        default:
            symbol = red;
    }
         
  return (
            <>
                <img className={styles.symbol} src={symbol} alt='symbol' />
            </>
        )
    }


export const SignalSum = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) return null;

  return (
    <div className={styles.signalSumflexEnd}>
      <p>Signalsummering</p>
      <div className={styles.signalSum}>
        <SignalSummary signalSummary={signalSummary} />
      </div> 
    </div>
  )
}

export const SignalSummary = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) return null;

  return (
    <>
      {
        signalSummary
        && signalSummary.length > 0
        && signalSummary.map(({ count, signalType },index) => (
          <p key={index} className={styles.signals}>{count} <Signals sig={signalType} /></p>
        ))
      }
    </>
  )
}

/*
const UserFeatures = ({ userFeatures }) => {
  if (!userFeatures || userFeatures.length === 0) return null;

  return (
    <>
      {
        userFeatures
        && userFeatures.length > 0
        && userFeatures.map(({ featureId, featureName }) => (
          <span key={featureId} className={featureName === 'hypertoni' ? styles.hypertoni : styles.alcohol}>
            {featureName}
          </span>
        ))
      }
    </>
  )
}
*/

const TableRow = ({ clients }) => {
    const history = useHistory();

    const dispRoute = (id) => {
        // dispatch(dispatchUserId(id));
        history.push('/patientoversikt/' + id);
    }

    return (
        <>
            {
                clients
                && clients.length > 0
                && clients.map(({ id, firstname, lastname, usercode, signalSummary, screeningDaysLeft, userFeatures }) => (
                    <tr key={id}>
                        <td className={styles.nameRow} onClick={() => dispRoute(id)}>{firstname} {lastname}</td>
                        <td>{usercode}</td>
                        { Math.sign(screeningDaysLeft) === 1 ? 
                          <td>{screeningDaysLeft}</td> :
                          <td>Avslutad</td>
                        }
                        <td className={styles.bin}>
                            <p className={styles.signals} onClick={() => dispRoute(id)}>Visa signaler</p>    
                            <SignalSummary signalSummary={signalSummary} />
                        </td>
                        {/*
                        <td className={styles.features}>  
                            <UserFeatures userFeatures={userFeatures} />
                        </td>
                        */}
                    </tr>
                ))
            }    
        </>
    )
}

export default TableRow
