import React from 'react';
import styles from './home.module.css';
import mobileBreth from '../images/mobilmatareUtanBG.png';
import { useHistory } from "react-router-dom";

export default function Home() {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.itemA}>
          <div>
            <img className={styles.imgMob} src={mobileBreth} alt='breathaliyzer' />
          </div>
      </div>
      <div className={styles.itemB}>
          <p className={styles.hInsight}>Välkommen till CRAS Insight</p>
          <p className={styles.pInsight}>Första steget är att fylla i din <span onClick={() => history.push('/userprofile')} >kundprofil</span> i menyn till vänster. Därefter är du redo att utforska systemet!
             Ett tips är att titta igenom dokumentet “Chefsmanual” som du hittar under fliken <span onClick={() => history.push('/documents')}>Dokument</span>.</p>
          <p className={styles.pInsight}>Vår <span onClick={() => history.push('/support')}>support</span> finns tillgänglig om du behöver hjälp!</p>
      </div>
    </div>
  );
}
