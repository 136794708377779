import React from 'react'
import { useParams, NavLink } from "react-router-dom";
import styles from './nav.module.css';
//import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

function Nav() {
  const { clientID } = useParams();

  return (
      <div className={styles.navFlex}>
          <div className={styles.nav}>
              <ul>
                  <li>
                      <NavLink to={`/patientoversikt/${clientID}`} activeClassName={styles.act}>Resultatöversikt</NavLink>
                  </li>
                  <li>
                      <NavLink to={`/matningar/${clientID}`} activeClassName={styles.act}>Resultat & Bilder</NavLink>
                  </li>
                  <li>
                      <NavLink to={`/schedule/${clientID}`} activeClassName={styles.act}>Blåsschema</NavLink>
                  </li>
                  <li>
                      <NavLink to={`/patientinformation/${clientID}`} activeClassName={styles.act}>Medarbetarinfo</NavLink>
                  </li>
              </ul>
             
          </div>
          <div>
              {/*
            <button className={styles.BtnQuit}>
                  <PowerSettingsNewIcon />
                  <p>Avsluta screening</p>
            </button>
              */}
            </div>    
          
      </div>
  )
}

export default Nav
