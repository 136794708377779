import React, { useState } from 'react';
import styles from './SideNav.module.css';
import logoutSvg from '../../svg/logout.svg';
//import house from '../../svg/house.svg';
import support from '../../svg/headphones-customer-support.svg'
//import Pdf from '../../pdf/Spec CRAS Insight nr.1 feb 2021.pdf';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
//import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
//import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImportContactsTwoToneIcon from '@material-ui/icons/ImportContactsTwoTone';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
//import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import sg from '../../svg/Group 34.svg'
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/user';
// import { logout } from '../../redux/therapistSlice';
// import { loggOutPatient } from '../../redux/patientSlice';
import { therapistUsersList } from '../../redux/therapistUsersSlice';
import Patient from './Patient'

function SideNav() {
    const [ showPatients, setShowPatients ] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const tableDATA = useSelector(therapistUsersList)

    const handleLogout = () => {
        dispatch(logout());
        // dispatch(logout());
        // dispatch(therapistLogout());
        // dispatch(loggOutPatient());
        history.push('/');
      }
      
    const controlHouse = () => {
        setShowPatients(true);
        // dispatch(loggOutPatient());
        history.push('/controlpanel/activepatient')
    }
 
    return (
        <>
            <div className={styles.sidenav}>
                <div className={styles.top} onClick={controlHouse} >
                    <div className={styles.house}>
                        {/* <img src={house} alt='house' /> */}
                        <img src={sg} alt="logout" height="24" width="24"/>
                    </div>
                    <p>Översikt</p>
                </div>
                <div>
                    <NavLink to="/shop" className={styles.bottomManDoc} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <ShoppingCartOutlinedIcon style={{color: "grey"}}/>
                        </div>
                        <p>Beställ alkomätare</p>
                    </NavLink>

                    <NavLink to="/addpatient" className={styles.bottomManDoc} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <AddCircleOutlineIcon style={{color: "grey"}}/>
                        </div>
                        <p>Lägg till Medarbetare</p>
                    </NavLink>

                    <NavLink to="/documents" className={styles.bottomManDoc} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <ImportContactsTwoToneIcon style={{color: "grey"}}/>
                        </div>
                        <p>
                            Dokument
                        </p> 
                    </NavLink>
                    
                    <NavLink to="/manuals" className={styles.bottomManDoc} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <ImageAspectRatioIcon style={{color: "grey"}}/>
                        </div>
                        <p>Tekniska Manualer</p>
                    </NavLink>     
                   
                    <NavLink to="/userprofile" className={styles.bottomManDoc} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <AssignmentIndOutlinedIcon style={{color: "grey"}}/>
                        </div>
                        <p>Kundprofil</p> 
                    </NavLink>
                </div>
            
                {
                    location.pathname !=='/homepage' && showPatients && false &&
                     <div className={styles.patients}>
                        <Patient dataUsers={tableDATA} />
                    </div>
                }
                <div className={styles.flex1}>

                </div>
                <div className={styles.bottom}>
                    <NavLink to="/Support" className={styles.bottomsupport} activeClassName={styles.act}>
                        <div className={styles.support}>
                            <img src={support} alt="support" />
                        </div>
                        <p>Support</p> 
                    </NavLink>
                    <div className={styles.bottomlogout} onClick={handleLogout}>
                        <div className={styles.logoutsvg}>
                            <img src={logoutSvg} alt="logout" />
                        </div>
                        <p>Logga ut</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SideNav
