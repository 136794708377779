import React, { useEffect, useState } from 'react'
import styles from './Schedule.module.css'
import { useSelector } from 'react-redux';
import axios from 'axios'
import { useHistory, useParams } from "react-router-dom";

const daysArr = ['blanch','Måndag','Tisdag','Onsdag','Torsdag','Fredag','Lördag','Söndag'];

const ScheduleRow = ({scheduleStart, scheduleEnd, scheduleItems}) => (
    <>
        <tr className={styles.scheduleStartTR}>
            <td>startar {scheduleStart}</td>
            <td>slutar {scheduleEnd}</td>
        </tr>
       {
           scheduleItems
           && scheduleItems.length > 0
           && scheduleItems.map(({scheduleItemId,scheduleItemTimeStart,scheduleItemTimeEnd,scheduleItemDayStart,scheduleItemDayEnd}) => (
                <tr key={scheduleItemId} className={styles.scheduleItemsTR}>
                    <td>Blåstillfälle: <strong>{scheduleItemTimeStart}-{scheduleItemTimeEnd}</strong></td>
                    <td>{daysArr[scheduleItemDayStart]}-{daysArr[scheduleItemDayEnd]}</td>
                </tr>
            ))
       }
    </>
)


const Schedule = () => {
    const { clientID } = useParams();
    const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);
    const [scheduleends, setScheduleend] = useState({});
    const [schedule, setSchedule] = useState([]);
    const history = useHistory();

      //----- ska flyttas till schema-----------
      const fetchscheduleEnds = async (patient) => {
        try {
            const res = await axios(`/api/scheduleends?targetUser=${patient.id}`)
            console.log(res.data);
            setScheduleend(res.data);
        } catch(err) {
            console.log('Error:', err);
        }   
    }
    const fetchschedule = async (patient) => {
        try {
            const res = await axios(`/api/schedule?targetUser=${patient.id}`)
            console.log(res.data);
            setSchedule(res.data);
        } catch(err) {
            console.log('Error:', err);
        }   
    }

    useEffect(() => {
        if (!patient) return;

            fetchscheduleEnds(patient);
            fetchschedule(patient);
        
    }
    ,[patient])

    if (!patient) return null;

    const { daysLeft, scheduleEnds } = scheduleends;
    const { schedules } = schedule;


    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Blåsschema</h2>
                <p>{patient.firstname} {patient.lastname} ({patient.usercode.toUpperCase()})</p>
            </div>
            <div className={styles.txtInfo}>
                <p>Vi rekommenderar 3 blåstillfällen per arbetsdag. Det är under dessa tillfällen som medarbetaren ska genomföra sina blås.
                     Medarbetaren får en påminnelse via SMS 30 och 15 minuter innan ett blåstillfälle tar slut.
                    Du kan ändra frekvens och tidpunkter för schemat genom att höra av dig till vår support. Kontaktuppgifter till supporten hittar du <span onClick={() => history.push('/Support')} >här.</span></p>
            </div>
            <div className={styles.messageBox}>
                <div className={styles.screeningHead}>
                     { (Math.sign(daysLeft) !== -1) ? 
                        (<p>Screeningen avslutas om <strong>{daysLeft}</strong> dagar, den <strong>{scheduleEnds}</strong></p>) :
                        <p> Screeningen är avslutad</p>
                     }
                
                </div>
                { Math.sign(daysLeft) === 1 && (
                    <table className={styles.scheduleTable}>
                        <thead>
                            <tr>
                                <th>Medarbetarens Schema</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                schedules
                                && schedules.length > 0
                                && schedules.map((item) => (
                                    <ScheduleRow key={item.scheduleId} {...item} />
                                ))
                            }
                        </tbody>
                    </table>
                )}
            </div>
         </div>
    )
}

export default Schedule
