import React,{ useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-modal';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from '../redux/user';
import '../App.css';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };
   
Modal.setAppElement('#root')
  

function IdleTimerContainer() {
    const [ modalIsOpen, setIsOpen ] = useState(false);
    const dispatch = useDispatch();
    const sessionTimeOutRef = useRef(null);
    const history = useHistory();

    const logOut = () => {
        setIsOpen(false);
        console.log('logOut');
        clearTimeout(sessionTimeOutRef.current);
        dispatch(logout());
        history.push('/');
    }

    const stayActive = () => {
        setIsOpen(false);
        console.log('stayActive');
        clearTimeout(sessionTimeOutRef.current);
    }
    
    const closeModal = () => {
        setIsOpen(false);
      }

    const handleOnIdle = () => {
        console.log('user is idle')
        setIsOpen(true);
        sessionTimeOutRef.current = setTimeout(logOut, 20000);
      }
    
      const handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
      }
    
      const { getRemainingTime } = useIdleTimer({
        timeout: 1000 * 1200,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500
      })

    return (
        <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
 
          <h3>Du har varit inaktiv i 20min</h3>
          <p>Å kommer att bli automatisk utloggad om du inte agerar</p>
          <div>
              <button className="btnModal" onClick={logOut}>logga ut</button>
              <button className="btnModal" onClick={stayActive}>Forsätt vara inloggad</button>
          </div>
          
         
        </Modal>
        </>
    )
}

export default IdleTimerContainer
