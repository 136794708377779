import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inituser = {
    id: 49,
    type: "user",
    usercode: "ddaa",
    firstname: "Kalle",
    lastname: "Karlsson",
    msisdn: "0706112659",
    email: "",
    supportMsisdn: "0706112659",
    supportName: "",
    therapistMsisdn: "070555555",
    therapistName: "eSanté CRAS",
    gdprAccept: false,
    customerId: 0,
    numberOfPushReceivers: 1,
    groups: [],
    periods: [],
    signalSummary: [
        {
            count: 14,
            createdAt: "May 10, 2020 9:12:00 PM",
            signalType: "abovePpmLimit"
        },
        {
            count: 21,
            createdAt: "Mar 28, 2020 3:15:03 PM",
            signalType: "missedScheduleItem"
        },
        {
            count: 23,
            createdAt: "May 12, 2020 11:18:56 AM",
            signalType: "underPpmLimit"
        }
    ],
    username: "dd",
    password: "hidden",
    appMode: "",
    ppmLimit: "0.00",
    clientID: "ojna",
    smsRecipients: "0706112659",
    smsLevel: "all",
    ppmVisible: "true",
    spontaneousBAC: "true"
}
const empty = {
    id: 0,
    type: "",
    usercode: "",
    firstname: "",
    lastname: "",
    msisdn: "",
    email: "",
    supportMsisdn: "",
    supportName: "",
    therapistMsisdn: "",
    therapistName: "",
    gdprAccept: false,
    customerId: 0,
    numberOfPushReceivers: 1,
    groups: [],
    periods: [],
    signalSummary: [
        {
            count: 0,
            createdAt: "",
            signalType: ""
        },
        {
            count: 0,
            createdAt: "",
            signalType: ""
        },
        {
            count: 0,
            createdAt: "",
            signalType: ""
        },
    ],
    username: "",
    password: "",
    appMode: "",
    ppmLimit: "",
    clientID: "",
    smsRecipients: "",
    smsLevel: "all",
    ppmVisible: "false",
    spontaneousBAC: "true"
}

export const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    user: inituser,
  },
  reducers: {
    chgFirstNamePatient: (state, action) => {
      state.user.firstname = action.payload;
    },
    loggOutPatient : state => {
        state.user = empty;
    },
    newPatient : (state, action) => {
      state.user = action.payload;
    }
  },
});

export const { chgFirstNamePatient, loggOutPatient, newPatient } = patientSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
//export const selectCount = state => state.counter.value;

export default patientSlice.reducer;


  export const dispatchUserId = (id) => async dispatch => {
    console.log('---dispatchUserId---')
    console.log(id);
    axios({
    method: 'GET',
    url: `/api/user?id=${id}`,
    })
    .then(({ data }) => {
        console.log(data);
        dispatch(newPatient(data.user));
    })
    .catch((err) => console.log(err));
}