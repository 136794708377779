import React, { useState, forwardRef } from 'react'
import styles from './ModuleCSS/AddPatient.module.css';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { newUser, newSchedule, sendRegSMS } from "../redux/user";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));


const StepOne = ({setMsisdn, next, setNewUserId}) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const classes = useStyles();

    const onSubmit = data => {
        dispatch(newUser(data))
        .then((resp) => {
            setNewUserId(resp.payload.data.userId)
            console.log(resp)
            }
        )
        .then(setMsisdn(data.msisdn))
        .then(next)
        .catch(console.log);
        //next();
    }

    return (
        <div>
            <h2>Lägg till medarbetare</h2>
           <div className={styles.flexNr}>
                <Avatar className={classes.large}>1</Avatar>
                <p>Fyll i din medarbetares uppgifter.</p>
            </div>
              
            <div className={styles.profile}>  
                <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div className={styles.form}>
                            <div className={styles.firstName}>   
                                <label>FÖRNAMN</label>
                                <input 
                                    {...register("firstname", {
                                        required: true,
                                        maxLength: 20,
                                        pattern: /^[A-Za-z]+$/i
                                    })} type="text" placeholder="Förnamn" />
                                    {errors.firstname && errors.firstname.type === "required" && <span>Du måste skriva in ett förnamn</span>}
                                    {errors.firstname && errors.firstname.type === "maxLength" && <span>Förnamnet är för långt!</span>}
                                    {errors.firsttname && errors.firstname.type === "pattern" && <span>Får bara innehålla bokstäver</span>}
                            </div>
                            <div className={styles.lastName}> 
                                <label >EFTERNAMN</label>
                                <input type="text" {...register("lastname", { required: true, maxLength: 40})} placeholder="Efternamn.."></input>
                                {errors.lastname && errors.lastname.type === "required" && <span>Du måste skriva in ett efternamn</span>}
                                {errors.lastname && errors.lastname.type === "maxLength" && <span>Efternamnet är för långt!</span>}

                            </div>
                            <div className={styles.phonenumber}>
                                <label>MEDARBETARENS TELEFONNUMMER</label>
                                <input type="text" {...register("msisdn", { required: true,  maxLength: 10, minLength: 8})} placeholder="Telefonnummer.."></input>
                                {errors.msisdn && errors.msisdn.type === "required" && <span>Du måste fylla i ett telefonnummer</span>}
                                {errors.msisdn && errors.msisdn.type === "maxLength" && <span>Telefonnummret har för många sifror. max 10</span>}
                                {errors.msisdn && errors.msisdn.type === "minLength" && <span>Telfonnummret innehåller för få siffror, min 8</span>}
                            </div>
                            {/*
                            <div className={styles.patientId}>
                                <div className={styles.flex}>
                                    <label>CRAS-ID</label>
                                    <Tooltip title={<span style={{ fontSize: "12px" }}>ID:t genereras slumpmässigt för att skydda din medarbetares integritet.</span>}>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <input type="text" name="org" ref={register} placeholder="MANI"></input>
                            </div>
                            */}
                            <div className={styles.altpatientId}>
                                <div className={styles.flex}>
                                    <label>EGET-ID</label>
                                    <Tooltip title={<span style={{ fontSize: "12px" }}>Ett ID genereras slumpmässigt för att skydda din medarbetares integritet.
                                     Här kan du skriva in ett eget unikt ID. Som då kommer att användas av systemet istället!</span>}>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <input type="text" {...register("clientid", { maxLength: 12 })} placeholder="Alternativt ID"></input>
                                {errors.clientid && errors.clientid.type === "maxLength" && <span>ID:et är för långt. max 12</span>}
                            </div>
                            <div className={styles.contactPhonenumber}>
                                <div className={styles.flex}>
                                    <label >TELEFONNUMMER FÖR RESULTATDELNING</label>
                                    <Tooltip title='"Medarbetaren kan inte se sin promillehalt utan får endast veta om det är ett rött eller grönt blås via appen. Resultaten kan emellertid delas via SMS till exempelvis en arbetsgivare."'>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <input type="text" {...register("smsrecipients", { required: true, maxLength: 10})}  placeholder="Telefonnummer" ></input>
                                {errors.smsrecipients && errors.smsrecipients.type === "required" && <span>Du måste fylla i ett telefonnummer</span>}
                                {errors.smsrecipients && errors.smsrecipients.type === "maxLength" && <span>Telefonnummret har för många sifror. max 10</span>}
                            </div>
                            
                            <div className={styles.signal}>
                                <div className={styles.flex}>
                                    <label >DELA RESULTAT VIA SMS VID:</label>
                                    <Tooltip title='"Här ser du vilka resultat som ska delas till telefonnumret för resultatdelning. Vi rekommenderar “Missade och över gränsvärdet”."'>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <select type="text" {...register("smslevel")}>
                                    <option value="">Missade och över gränsvärde</option>
                                    <option value="all">Alla signaler</option> 
                                </select>
                            </div>
                            
                    </div>
                       {/* <button className={styles.btnCancel} type="submit">Avbryt</button> */}
                        <button className={styles.btn} type="submit">Spara & gå vidare</button>
                    
                  </form>
                
                </div>
        </div>
    )
}

const StepFour = ({prev}) => {
    const history = useHistory();
    return (
        <div className={styles.stepFour}>
            <h2>Du har nu lagt till din medarbetare!</h2>
            {/* <button className={styles.btn} onClick={Prev}>Tillbaka</button> */}
            
            <p>Undersökningen börjar vid schemats start. Du kan ändra både medarbetarinfo och schema när som helst. Tveka inte att kontakta vår <span onClick={() => history.push('/Support')}>support</span> om du behöver hjälp!</p>
            <p>Kom ihåg att din medarbetare behöver en alkomätare för att kunna utföra testerna</p>
            <button className={styles.btnF} onClick={() => history.push('/shop')}>Beställ alkomätare</button>
            <button className={styles.btnF} onClick={() => history.push('/controlpanel/activepatient')}>Till  översikt</button>
        </div>
    )
}

const Sched = ({next, newUserId}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date());

    const handleBtn = () => {
        const d = new Date(startDate);
        d.setDate(startDate.getDate() + 60);      
        const scheduleend = (d.toISOString().slice(0,10));
        const schedulestart = (startDate.toISOString().slice(0,10));

        const data = {
            scheduleuserid: newUserId,
            scheduleend,
            schedulestart,
        }
        
        dispatch(newSchedule(data))
        .then((resp) => {
            console.log(resp.payload.data.userId)
            console.log(resp)
            }
        )
        .then(next)
        //.then(console.log)
        .catch(console.log);
      }

      const ExampleCustomInput = forwardRef(
        ({ value, onClick }, ref) => (
          <button className={styles.ec_input} onClick={onClick} ref={ref}>
            {value}
          </button>
        ),
      );

    return (
     <>
         <h2>Lägg till medarbetare</h2>
             <div className={styles.flexNr}>
                <Avatar className={classes.large}>3</Avatar>
                <p>Lägg upp ett schema med blåstillfällen. Det är under dessa tillfällen som medarbetaren ska genomföra sina blås.
                     Medarbetaren får en påminnelse via SMS 30 och 15 minuter innan ett blåstillfälle tar slut.</p>
            </div>
                <p>Vi rekommenderar schemat nedan med 3 blåstillfällen/vardag. Schemat löper 2 månader från startdatumet.
                     Du kan ändra frekvens och tidpunkter för schemat genom att kontakta vår support</p>
            <table className={styles.scheduleTable}>
                <tbody>
                    <tr className={styles.scheduleItemsTR}>
                        <td>Blåstillfälle: <strong>06:00-08:00</strong></td>
                        <td>Måndag-Fredag</td>
                    </tr>
                    <tr className={styles.scheduleItemsTR}>
                        <td>Blåstillfälle: <strong>11:00-13:00</strong></td>
                        <td>Måndag-Fredag</td>
                    </tr>
                    <tr className={styles.scheduleItemsTR}>
                        <td>Blåstillfälle: <strong>16:00-18:00</strong></td>
                        <td>Måndag-Fredag</td>
                    </tr>
                </tbody>
            </table>
            <p>VÄLJ STARTDATUM FÖR SCHEMAT</p>
            <div className={styles.datePick_Btn}>
                <DatePicker 
                    selected={startDate}
                    onChange={date => setStartDate(date)} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<ExampleCustomInput />}
                    minDate={new Date()}
                />
                <DateRangeIcon style={{color: "grey"}} fontSize="large"/>
                
            </div>
            <button className={styles.btn} onClick={handleBtn}>Aktivera rekommenderat schema</button>
    {/*
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form}>
                <div className={styles.firstName}>   
                    <label>SCHEMA STARTDATUM</label>
                    <input className={styles.firstName} type="text" name="schedulestart" ref={register({required: true })} />
                </div>
            </div>
            <button className={styles.btn} type="submit">Aktivera rekommenderat schema</button>
        </form>
    */}
    </>
    );
}

function AddPatient() {
    const [step, setStep] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [msisdn, setMsisdn] = useState("1234")
    const [newUserId, setNewUserId] = useState(49);

    const next = () => {
        console.log(step);
        if (step > 3) return;
        setStep(step + 1);
    }
    const prev = () => {
        console.log(step);
        if (step < 1) return;
        setStep(step - 1);
    }
    const sendSMS = () => {
        console.log('sendSMS ' + newUserId);
        dispatch(sendRegSMS({userID: newUserId, message: "Test sms message"}))
        .then(alert(`Ett installations sms har skickats till ${msisdn}`))
        .then(next)
        .catch(console.log); 
    }

    return (
        <div className={styles.container}>
            
            {/* <button className={styles.btn} onClick={Prev}>Tillbaka</button> */}
            
            { step === 0 && (
                <>
                <h2 className={styles.conH2}>Lägg till medarbetare</h2>
                <p>Kom ihåg att du behöver en alkomätare på plats innan undersökningen kan starta. Alkomätare kan beställas <span onClick={() => history.push('/shop')}>här.</span></p>
                <div>
                    <div className={styles.flexNr}>
                        <Avatar className={classes.large}>1</Avatar>
                        <p>Fyll i din medarbetares uppgifter.</p>
                    </div>
                    <div className={styles.flexNr}>
                        <Avatar className={classes.large}>2</Avatar>
                        <p>Skicka ett aktiverings-SMS till din medarbetare från CRAS. SMS:et hjälper till att hämta och aktivera appen.</p>
                    </div>
                    <div className={styles.flexNr}>
                        <Avatar className={classes.large}>3</Avatar>
                        <p>Lägg upp ett schema med blåstillfällen.</p>
                    </div>
                </div>
                <button className={styles.btn} onClick={next}>Sätt igång</button>
                </>
            )}
            { step === 1 && <StepOne setMsisdn={setMsisdn} next={next} setNewUserId={setNewUserId}/> }
            { step === 2 && (
                 <div>
                 <h2>Lägg till medarbetare</h2>
                  <div className={styles.flexNr}>
                     <Avatar className={classes.large}>2</Avatar>
                     <p>Skicka ett aktiverings-SMS till din medarbetare från CRAS. SMS:et hjälper till att hämta och aktivera appen.</p>
                 </div>
                 <div className={styles.msisdn}>
                     <p>MEDARBETARENS TELEFONNUMMER</p>
                     <div>{msisdn}</div>
                 </div>
                 
                 <button className={styles.btn} onClick={sendSMS}>Skicka SMS & gå vidare</button>
             </div>
            )}
            { step === 3 && <Sched next={next} newUserId={newUserId}/> }
            { step === 4 && <StepFour Prev={prev}/> }
        
                    {/* false &&
                        (
                        <>
                            <p style={{fontSize: 24, marginTop: 20}}>Behandlingsinformation</p>
                            <div className={styles.formbottom}>
                                    <p className={styles.treatmentmethod}>
                                        <label>BEHANDLINGSMETOD</label>
                                        <select type="text" name="method" ref={register}>
                                            <option value="scaledown">Nykterhetskontroll</option>
                                            <option value="">Kontrollerat drickande</option>
                                        </select>
                                    </p>
                                    <p className={styles.threshold}>
                                        <label>TRÖSKELVÄRDE</label>
                                        <select type="number" name="threshold" ref={register}>
                                            <option value="0.00">0.00</option>
                                            <option value="0.05">0.05</option>
                                            <option value="0.15">0.15</option>
                                            <option value="0.20">0.20</option>
                                            <option value="0.25">0.25</option>
                                            <option value="0.30">0.30</option>
                                            <option value="0.40">0.40</option>
                                            <option value="0.50">0.50</option>
                                            <option value="0.60">0.60</option>
                                            <option value="0.70">0.70</option>
                                            <option value="0.80">0.80</option>
                                            <option value="0.90">0.90</option>
                                            <option value="1.00">1.00</option>
                                            <option value="1.10">1.10</option>
                                            <option value="1.20">1.20</option>
                                            <option value="1.30">1.30</option>
                                            <option value="1.40">1.40</option>
                                            <option value="1.50">1.50</option>
                                        </select>
                                    </p>
                                    <div className={styles.showResult}>
                                        <label>VISA MÄTRESULTAT FÖR PATIENTEN?</label>
                                        <div className={styles.radio}>
                                            <input type="radio" id="no" name="showresult" value="no" ref={register} ></input>
                                            <label htmlFor="no">Nej</label>
                                            <input type="radio" id="yes" name="showresult" value="yes" ref={register} ></input>
                                            <label htmlFor="yes">Ja</label>
                                        </div>
                                    </div>
                                   
                                   
                                <button className={styles.btnCancel} type="submit">Avbryt</button>    
                                <button className={styles.btn} type="submit">Lägg till Patient</button>    
                            </div>
                        
                        </> 
                    )*/}
        </div>
    )
}

export default AddPatient
