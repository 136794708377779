import React, { useState}  from 'react'
import styles from './ModuleCSS/Therapistprofile.module.css';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { updateTherapist } from '../redux/therapistSlice';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

function TherapistProfile() {
    const { register, handleSubmit, errors } = useForm();
    const therapist = useSelector(state => state.therapist.user);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const onSubmit = data => {
        dispatch(updateTherapist(data));
        setOpen(true);
        console.log(data);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
    return (
        <>
        <div className={styles.container}>
            
            {/*<p style={{fontSize: 36, paddingLeft: 20}}>Behandlarprofil</p>*/}
            <p style={{fontSize: 24, paddingLeft: 20, marginTop: 20}}>Kunduppgifter</p>
              
            <div className={styles.profile}>  
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <p>   
                                <label>FÖRNAMN</label>
                                <input type="text" name="firstname" ref={register({required: true, maxLength: 20 })} defaultValue={therapist.firstname} placeholder="Ditt förnamn" />
                                {errors.firstname && <span>Du måste skriva in Förnamn</span>}
                            </p>
                            <p> 
                                <label >EFTERNAMN</label>
                                <input type="text" name="lastname" ref={register({required: true, maxLength: 20, pattern: /^[A-Za-z]+$/i })} defaultValue={therapist.lastname} placeholder="Ditt efternamn.."></input>
                                {errors.lastname && <span>Du måste skriva in efternamn</span>}
                            </p>
                            <p>
                                <label >TELEFONNUMMER</label>
                                <input type="text" name="phonenr" ref={register} placeholder="Ditt telefonnummer.."></input>
                            </p>
                            <p>
                                <label >ORGANISATION</label>
                                <input type="text" name="org" ref={register} placeholder="Organasitation.."></input>
                            </p>
                            <p>
                                <label >ORGANISATIONSNUMMER</label>
                                <input  type="text" name="orgnr" ref={register({ min: 2, max: 10 })} placeholder="organsnr" ></input>
                            </p>
                            <p>
                                <label >Mailadress</label>
                                <input type="email" name="email" ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "ogiltig email adress"
                                        }
                                })} placeholder="Din mailadress.."></input>
                                {errors.email && <span>Du måste skriva in din mailadress,  </span>}
                                {errors.email && errors.email.message}
                            </p>
                            <p>
                                <label >Adress för leverans av mätare</label>
                                <input type="text" name="address" ref={register} placeholder="Leverans address.."></input>
                            </p>
                            <p>
                                <label >Ort</label>
                                <input type="text" name="city" ref={register} placeholder="Stad"></input>
                            </p>
                    <button className={styles.btn} type="submit">Spara ändringar</button>
                </form>  
            </div>
        </div>

        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                <Alert severity="success">
                    <AlertTitle>Ditt användarnamn har Ändrats till:</AlertTitle>
                    <strong>{therapist.username}</strong>
                    <p>Logga ut! Logga igen med det nya användarnamnet, samma lösen</p> 
                </Alert>
        </Snackbar>
    </>
    )
}

export default TherapistProfile
