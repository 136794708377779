import React from 'react';
import styles from './pages.module.css';
import Nav from '../components/Nav';
import SideNav from '../components/sideNav/SideNav';
import Support from '../components/Support';
import PatientOverview from '../components/patientOverview/PatientOverview';
import Measurements from '../components/MeasurementCard/Measurements';
import Message from '../components/Message';
import Strategies from '../components/Strategies';
import Patientinformation from '../components/Patientinformation';
import TherapistProfile from '../components/TherapistProfile';
import AddPatient from '../components/AddPatient';
import ControlPanel from '../components/ControlPanel/ControlPanel'
import AnalysTest from '../components/analystest';
import Schedule from '../components/schedule/Schedule';
import Home from './Home';
import Documents from '../components/documents';
import Manuals from '../components/manuals';
import Shop from '../components/shop';
import UserProfile from '../components/UserProfile';

export function PageLayout ({ sidenav, nav, children }) {
  return (
    <div className={styles.container}>
        <div className={styles.sidenav}>
            { sidenav && <SideNav /> }
        </div>
        <div className={styles.area}>
            <div className={styles.nav}>
                { nav && <Nav /> }
            </div>
            <div className={styles.main}>
              {children}
            </div>
        </div>
    </div>
  )
}

export function HomePage () {
  return (
    <PageLayout sidenav={true}>
      <Home />
    </PageLayout>
  );
}

export function SupportPage () {
    return (
      <PageLayout sidenav={true}>
        <Support />
      </PageLayout>
    );
};

export function AnalystestPage () {
    return (
      <PageLayout sidenav={true} nav={true}>
        <AnalysTest />
      </PageLayout>
    );
};

export function PatientOverviewPage () {
    return (
      <PageLayout sidenav={true} nav={true}>
        <PatientOverview />
      </PageLayout>
    );
};

export function MeasurementsPage () {
    return (
      <PageLayout sidenav={true} nav={true}>
        <Measurements />
      </PageLayout>
    );
};

export function MessagePage () {
    return (
      <PageLayout sidenav={true} nav={true}>
        <Message />
      </PageLayout>
    );
};

export function StrategiesPage () {
    return (
      <PageLayout sidenav={true} nav={true}>
        <Strategies />
      </PageLayout>
    );
};

export function PatientinformationPage() {
    return (
      <PageLayout sidenav={true} nav={true}>
        <Patientinformation />
      </PageLayout>
    ); 
};

export function TherapistProfilePage() {
    return (
      <PageLayout sidenav={true} nav={false}>
        <TherapistProfile />
      </PageLayout>
    ); 
};

export function AddPatientPage() {
    return (
      <PageLayout sidenav={true} nav={false}>
        <AddPatient />
      </PageLayout>
    ); 
};

export function ControlPanelPage() {
    return (
      <PageLayout sidenav={true} nav={false}>
        <ControlPanel/>
      </PageLayout>
    ); 
};

export function SchedulePage() {
    return (
      <PageLayout sidenav={true} nav={true}>
        <Schedule/>
      </PageLayout>
    ); 
};

export function ManualsPage () {
    return (
      <PageLayout sidenav={true} nav={false}>
        <Manuals />
      </PageLayout>
    );
};

export function DocumentsPage () {
    return (
      <PageLayout sidenav={true} nav={false}>
        <Documents />
      </PageLayout>
    );
};

export function ShopPage () {
    return (
      <PageLayout sidenav={true} nav={false}>
        <Shop />
      </PageLayout>
    );
};

export function UserProfilePage() {
    return (
      <PageLayout sidenav={true} nav={false}>
        <UserProfile />
      </PageLayout>
    ); 
};
