import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
  } from "react-router-dom";
import styles from './ControlPanel.module.css'
import sg from '../../svg/Group 34.svg'
import ap from '../../svg/Group 35.svg'
import ActiveTable from './ActiveTable'
import EndedTable from './EndedTable'

import { useDispatch } from 'react-redux';
import { fetchClients } from '../../redux/user';

export default function ControlPanel() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchClients())
      .then(() => console.log())
      .catch((err) => console.log(err));
  }, [dispatch]);

    let { path, url } = useRouteMatch();
    
    return (
        <div className={styles.nav}>
                <ul>
                    <li>
                        <NavLink to={`${url}/activepatient`} activeClassName={styles.act}>Aktiva medarbetare</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${url}/endedpatient`} activeClassName={styles.act}>Avslutade medarbetare</NavLink>
                    </li>
                </ul>
            {/*}
                <div className="controlBtn">
                    <NavLink to="/controlpanel">Kontrollpanelen</NavLink>
                </div>
            */}
                <Switch>
                    <Route exact path={path}>
                        <h3>Please select a topic.</h3>
                    </Route>
                    <Route path={`${path}/activepatient`}>
                        <PatientTable type={'active'} />
                    </Route>
                    <Route path={`${path}/endedpatient`}>
                        <PatientTable type={'ended'} />
                    </Route>
                </Switch>
              
        </div>
    );
}

export const PatientTable = ({ type }) => {
    const history = useHistory();
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Översikt</h2>
                {/* <button className={styles.btnAddPatient} onClick={() => history.push('/addpatient')} type="button">+ Lägg till patient</button> */}
            </div>
            <div className={styles.controlBox}>
                <div className={styles.item1}>
                { type === "active" ? ( <ActiveTable />
                ) : (
                    <EndedTable />
                )}
                </div>
                <div className={styles.item2}>
                    <h4>Aktuellt</h4>
                    <h5>2021.03.18</h5>
                    <p>Nu kan du ladda hem en signalrapport som pdf</p>
                    <h5>2021.03.16</h5>
                    <p>Uppdaterad vy i Blåsschema </p>
                </div>
                <div className={styles.item3} onClick={() => history.push('/Support')}>
                    <img src={ap} alt='support' />
                    <h4>Behöver du support?</h4>
                    <p>Vi hjälper dig med scheman, upläggning av Medarbetare etc.</p>
                </div>
                <div className={styles.item4} onClick={() => history.push('/addpatient')}>
                    <img src={sg} alt='add Patient' />
                    <h4>Lägg till Medarbetare</h4>
                    <p>Lägg till nya Medarbetare</p>
                </div>
            </div>
        </div> 
    )
}
