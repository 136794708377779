import React, { useState } from 'react';
import styles from './header.module.css';
//import craslogo from '../images/craslogo.png'
import crasInsight from '../images/crasInsight.png';
import LetterAvatars from'./materialUIComp/LetterAvatars'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../redux/user';
// import { logout } from '../redux/therapistSlice';
// import { therapistLogout } from '../redux/therapistUsersSlice';
// import { loggOutPatient } from '../redux/patientSlice';


export default function HeadCras() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLANG, setAnchorLANG] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  //const location = useLocation();
  //const therapist = useSelector(state => state.therapist.user);
  const user = useSelector(({ user: { self }}) => self);
  const dispatch = useDispatch();
  const lngi18n = false;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    handleCloseLANG();
  };

  const handleClickLANG = (event) => {
    setAnchorLANG(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    console.log(logout);
    dispatch(logout());
    // dispatch(logout());
    // dispatch(therapistLogout());
    // dispatch(loggOutPatient());
    history.push('/');
  }

  const handleCloseLANG = () => {
    setAnchorLANG(null);
  };

  const toHomePage = () => {
    // dispatch(loggOutPatient());
    history.push('/');
  }

    return (
        <div className={styles.header}>
          <div className={styles.logo} onClick={toHomePage} >
            <img src={crasInsight} alt="CRAS Loggo" width="160" />
          </div>
          
          <div className={styles.cras}></div>

          { 
            user !== null
            && (
              <div className={styles.right}>
                <LetterAvatars color="secundary" firstName={user.firstname} lastName={user.lastname} />
                <div className={styles.therapist} >
                  <p style={{fontSize: 16}}>{user.firstname} {user.lastname}</p>
                  {/*<p style={{fontSize: 12}}>{user.type}</p> */}
                  {/*<p style={{fontSize: 12}}>{ user.type === "therapist" ? "HR" : "Behandlare"}</p> */}
                </div>

                <Tooltip title="Din profil">
                  <IconButton onClick={handleClick} color="primary">
                    <ExpandMoreIcon  style={{color: "white"}}/>
                  </IconButton>
                </Tooltip>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => history.push('/userprofile')}>{t('Profile')}</MenuItem>
                  <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                </Menu>
                { lngi18n &&
                  (
                    <>
                    <Tooltip title={t('Change Lang')}>
                      <IconButton onClick={handleClickLANG} color="primary">
                        <LanguageIcon style={{color: "white" }}  />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      id="langue-menu"
                      anchorEl={anchorLANG}
                      keepMounted
                      open={Boolean(anchorLANG)}
                      onClose={handleCloseLANG}
                    >
                      <MenuItem onClick={() => changeLanguage('en')}>en</MenuItem>
                      <MenuItem onClick={() => changeLanguage('sv')}>sv</MenuItem>
                      <MenuItem onClick={() => changeLanguage('nk')}>nk</MenuItem>
                      <MenuItem onClick={() => changeLanguage('dk')}>dk</MenuItem>
                    </Menu>
                    </>
                )}
              </div>
            )
          }
        </div>
    );
};
