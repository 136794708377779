import React, { useState, useEffect } from 'react'
import Signals from './Signals'
import axios from 'axios'
import styles from './PatientOverview.module.css'
import Snackbar from '@material-ui/core/Snackbar';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PDFDownloadLink }  from '@react-pdf/renderer';
import Quixote from './signalPdf';
import Modal from 'react-modal';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  Modal.setAppElement('#root')
/*
 const signalsWithBrethRes = (signals, breth) => {
    /*
    console.log('---signlas---'); 
    console.log(signals.signals);
    console.log('---breth---');
    console.log(breth.BreathalizerResult);
  /*
/*
    const myArray = [{id: 1, name:'pipi'}, {id: 2, name:'popo'}];
    console.log(myArray);
    const id = 50;
    */
    //const variableOne = breth.BreathalizerResult.filter(itemInArray => itemInArray.resultId === id);
    //const variableOne = signals.signals.filter(itemInArray => itemInArray.resultId === id);
    //const variableOne = breth.BreathalizerResult.filter(o1 => signals.signals.some(o2 => o1.resultId === o2.resultId));
    //let result = result1.filter(o1 => result2.some(o2 => o1.id === o2.id));
    /*
    console.log('---some---'); 
    console.log(variableOne);
*/
/*
    let finalSignal = [];
    signals.signals.forEach(sig => {
      if (sig.resultId !== null) {
    finalSignal.push( Object.assign( {}, sig, 
         { ktm: breth.BreathalizerResult.find(m => m.resultId === sig.resultId).result } 
    ))
      }
  });

    let finalSignal = [];
    signals.signals.forEach(sig => {
      if (sig.resultId === null) {
        finalSignal.push( Object.assign( {}, sig ))
      } else {
        finalSignal.push( Object.assign( {}, sig, 
          { breathRes: breth.BreathalizerResult.find(m => m.resultId === sig.resultId) } ))
      }
    });

    console.log('---final signal with result---')
    console.log(finalSignal);

  /*
    var members = [{docId: "1234", userId: 222}, {docId: 66, userId: 333}];
    var memberInfo = [{id: 222, name: "test1"}, {id: 333, name: "test2"}];
    console.log(members);
    console.log(memberInfo);

    let finalArray = [];
    memberInfo.forEach(member => {
    finalArray.push( Object.assign( {}, member, 
         { BMW: members.find(m => m.userId === member.id).docId } 
    ))
    console.log(finalArray);
    });
    
 }*/
/*
 var result = result1.filter(function (o1) {
  return result2.some(function (o2) {
      return o1.id === o2.id; // return the ones with equal id
 });
});
// if you want to be more clever to find those in common:
let result = result1.filter(o1 => result2.some(o2 => o1.id === o2.id));

// To find those in 1 NOT in 2:
let result = result1.filter(o1 => !result2.some(o2 => o1.id === o2.id));
*/



const SignalsTable = ({ patient }) => {
    const [signals, setSignals] = useState();
    const [isLoading, setIsLoading] = useState(true)
    const [signalId, setSignalId] = useState();
    const [open, setOpen] = useState(false);
    const [resultOfMarked, setResultOfMarked] = useState({});
    const [offset, setOffset] = useState(0);
    const limit = 180;
    //const [modalIsOpen,setIsOpen] = useState(false);
    const crasPro = false;
    const [creatingPDF, setCreatingPDF] = useState(false);
    console.log('--rendering-SignalTable--');
    const handleClick = () => {
        setOpen(true);
      };
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

      
        /*
        setSignalsBreath(finalSignal);
        console.log('---final signal with result---')
        console.log(finalSignal);
        */
     
    const postSignalReadAll = async () => {
        try {
            const result = await axios.post(`/api/signalsAsRead?targetUser=${patient.id}&signalId=${signalId}`)
            console.log(result.data);
            setResultOfMarked(result.data);
            handleClick();
            //fetchSignals(patient.id)
        } catch(err) {
            console.log('Error:', err);
        }
    }

    const createPDF = () => setCreatingPDF(true);
 
    useEffect(() => {

      if (!patient) return;

      const signalsWithBrethRes = (sigRes, breth) => {
        const { nr } =sigRes;
        let finalSignal = [];
        sigRes.signals.forEach(sig => {
          if (sig.resultId === null) {
            finalSignal.push( Object.assign( {}, sig ))
          } else {
            finalSignal.push( Object.assign( {}, sig, 
              { breathRes: breth.BreathalizerResult.find(m => m.resultId === sig.resultId) } ))
          }
        });

        return {
          signals: finalSignal,
          nr,
          patient,
        }
      }
      
      const fetchSignals = async () => {
        try {
            const res = await axios(`/api/signalsPage?targetUser=${patient.id}&limit=${limit}&offset=${offset}`)
            const brethRes = await axios(`/api/secure/breathalizerResults?targetUser=${patient.id}&limit=${limit}&offset=${offset}&images=false`)
            //setSignals(res.data)
            
            console.log("--SignalTable--fetchSignals");
            console.log(res.data);
            console.log('--signalSumm--');
            console.log(patient);
            /*
            console.log("--SignalTable--brethRes");
            console.log(brethRes.data);
            */
            const signals = signalsWithBrethRes(res.data, brethRes.data, patient);
            console.log(signals);
            setSignals(signals)
            //setSignalsBreath(signals);

            if (res.dat && res.dat > 0) setSignalId(res.data.signals[0].signalId);
            setIsLoading(false)  
        } catch(err) {
            console.log('Error:', err);
        }   
    }
      fetchSignals();
           console.log('--rending-useEffect-signalTable--');
    }, [patient, offset])

    return (
        <>
        {isLoading ? (
            <p>loading...</p>
        ) : (
            <>
             <div className={styles.headBtn}>
                 {
                     crasPro &&
                     <button className={styles.BtnMarkSignal} onClick={postSignalReadAll}>Markera allt som läst</button>
                 }

                { !creatingPDF 
                  ? (
                    <button className={styles.BtnMarkSignal} onClick={createPDF}>
                      <CloudDownloadOutlinedIcon />
                      <p>Skapa signalrapport</p>
                    </button> 
                  ) : (
                    <PDFDownloadLink
                      document={<Quixote {...signals}
                      user={patient.usercode.toUpperCase()} />}
                      className={styles.BtnMarkSignal}
                      fileName={`SignalRapport_${patient.usercode.toUpperCase()}_${new Date().getSeconds()}.pdf`}
                    >
                      {({ loading, error }) => {
                        console.log({ loading, error });

                        if (loading) {
                          return (
                            <>
                              <CloudDownloadOutlinedIcon />
                              <p>Skapar signalrapport..</p>
                            </>
                          );
                        }

                        return (
                          <>
                            <CloudDownloadOutlinedIcon />
                            <p>Ladda ner signalrapport</p>
                          </>
                        );
                      }}
                    </PDFDownloadLink>
                  )
                }

                <p>Totalt antal signaler: {signals.nr} </p>
                <div className={styles.paginBtn}>
                    
                    <button className={styles.btnMonth} onClick={() => setOffset(Math.max(offset - limit, 0))}>Framåt</button>
                    <button className={styles.btnMonth} onClick={() => setOffset(Math.min(signals.nr - limit, offset + limit))}>Bakåt</button>
                </div>
            </div>

            <div>
                
                <Modal
                    isOpen={false}
                    // isOpen={modalIsOpen}
                    //onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    shouldCloseOnEsc={true}
                    >
                    <div className={styles.modalFlex}>
                        <h2>Vill du Ladda ner signal rapport</h2>
                        <button className={styles.BtnX}>X</button>
                    </div>
                    {/* <button className={styles.BtnMarkSignal} onClick={closeModal}>Nej</button> */}
                    <PDFDownloadLink document={<Quixote {...signals} user={patient.usercode.toUpperCase()} />} className={styles.BtnGetSignal} fileName={`SignalRapport_${patient.usercode.toUpperCase()}_${new Date().getSeconds()}.pdf`}>
                      {
                        ({ url, loading, error }) => (
                          <p>{ loading ? 'Laddar' : 'Hämta rapport' }</p>
                        )
                      }
                    </PDFDownloadLink>
                </Modal>
            </div>

            <table className={styles.signalTable}>
            <thead>
                <tr>
                    <th>
                        <div className={styles.flex}>
                            <p>Signal</p>
                            <Tooltip title={<span style={{ fontSize: "12px" }}>
                                    <p>Grön = Blås med resultat under gränsvärdet (gränsvärdet sätts till 0,1‰ om du inte valt att ändra det)</p>
                                    <p>Gul = Missat blås</p>
                                    <p>Röd = Blås med resultat över gränsvärdet</p>
                                </span>}>
                                <InfoOutlinedIcon style={{color: "blue"}}/>
                            </Tooltip>
                        </div>
                    </th>
                    <th>Resultat</th>
                    <th>Beskrivning</th>
                    <th>Tidpunkt</th>
                    <th>Gränsvärde</th>
                    <th>{crasPro && "status" }</th>
                </tr>
            </thead>
            <tbody>
                {signals.signals.map((sig) => (
                    <Signals key={sig.signalId} sig={sig} />
                ))}
            </tbody>
        </table>
        </>
        )}
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <Alert severity="success">
            <AlertTitle>Signaler har blivit lästa!</AlertTitle>
              <strong>{resultOfMarked.NrEffectedRows}</strong> signaler har markerats som läst
        </Alert>
      </Snackbar>
     </>   
    )
}

export default SignalsTable
