import React from 'react'
import styles from './manuals.module.css';
import vector from '../images/Vector.png'
//import ReactPlayer from 'react-player'

const Manuals = () => {
    return (
        <div className={styles.container}>
            <h2>Tekniska manualer</h2>
            <div className={styles.txtInfo}>
                <p>Här finner du manualer för hur medarbetaren ska komma igång med det tekniska, både appen och alkomätaren.
                     Välj iPhone eller Android beroende vilken mobiltelefon din medarbetare använder.</p>
            </div>
            <div className={styles.wrapper}>
            
                <div className={styles.item4}>
                    <a href="https://www.dropbox.com/s/fm34hlit726lbs6/CRAS_Instruktionsmanual_iOS_2019.pdf?dl=1" target="_blank" rel="noopener noreferrer">Manual för iPhone</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item5}>
                    <a href="https://www.dropbox.com/s/y7lrvephf2gxtnd/CRAS_Instruktionsmanual_Android_2019.pdf?dl=1" target="_blank" rel="noopener noreferrer">Manual för Android</a>
                    <img src={vector} alt='vector' />
                </div>
                {/*
                <div className={styles.item6}>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item7}>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item8}>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item9}>
                    <img src={vector} alt='vector' />
                </div>
                */}
            </div>
            {/*}
            <div className={styles.videoContainer}>
                <h3>Instruktions Filmer</h3>
                <div className={styles.videoWrapper}>
                    <ReactPlayer className={styles.videoPlayer} width='240px' height='120px' controls url='https://youtu.be/Fw31_ACLMos' />
                    <ReactPlayer className={styles.videoPlayer} width='240px' height='120px' controls url='https://youtu.be/pLQo5UhikGs' />
                </div>
            </div>
            */}
        </div>
    )
}

export default Manuals
