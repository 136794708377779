import React from 'react'
import { Page, Text, View, Document, Image, StyleSheet, Font }  from '@react-pdf/renderer';
//import bac from '../../images/bactrac-ios-cras 1@2x.png'
import insigtBlue from '../../images/CRAS_Insight_logo_blue.png';
//import signalUnderPpmLimit from '../../images/signal-underPpmLimit.png'
//import signalMissedScheduleItem from '../../images/signal-missedScheduleItem.png'
//import signalAbovePpmLimit from '../../images/signal-abovePpmLimit.png'
//import craslogo from '../../images/craslogo.png'
//import nope from '../../images/nope.png'
//import gr from '../../images/Group 35.png'
//import gs from '../../images/Group 34.png'
import signalUnderPpmLimit from '../../images/underPpmLimit.png'
import signalMissedScheduleItem from '../../images/missedScheduleItem.png'
import signalAbovePpmLimit from '../../images/srinkAbovePpm.png';

const Signals = ({ sig }) => {
    //const { signalType: sigType, readAt } = sig;
    //let symbol, sigTypeText, styleReadAt;
    const { signalType: sigType } = sig;
    let symbol = signalAbovePpmLimit, sigTypeText;

    switch (sigType) {
        case "underPpmLimit":
            symbol = signalUnderPpmLimit;
            sigTypeText = 'Utandningstest inom gränsvärdet';
            break;
        case "abovePpmLimit":
            symbol = signalAbovePpmLimit;
            sigTypeText = 'Överskridet gränsvärde';
            break;
        case "missedScheduleItem":
            symbol = signalMissedScheduleItem;
            sigTypeText = 'Missat utandningstest';
            break;
        default:
            symbol = signalMissedScheduleItem;
    };

    return (
      <>
        <Image
          style={styles.symImg}
          src={symbol}
          />
          <Text style={[styles.textRes, {width: 80}]}>{sig.breathRes && sig.breathRes.result}</Text>
          <Text style={[styles.textRes, {width: 170}]}>{sigTypeText}</Text>
        </>
    );
  };

  const SignalsSum = ({ sig }) => {
    let symbol;

    switch (sig) {
        case "underPpmLimit":
            symbol = signalUnderPpmLimit;
            break;
        case "abovePpmLimit":
            symbol = signalAbovePpmLimit;
            break;
        case "missedScheduleItem":
            symbol = signalMissedScheduleItem;
            break;
        default:
            symbol = signalMissedScheduleItem;
    };

    return (
      <>
        <Image
          style={{width: 15, height: 15, marginRight: 4, marginLeft: 4}}
          src={symbol}
          />
        </>
    );
  };

const Rows = ({signals}) => {
  return (
    <>
    {
      signals
      && signals.length > 0
      && signals.map((item) => (
        <View key={item.signalId} style={styles.tableRow}>
          {/*<Text style={[styles.textRes, {width: 50}]}>symb</Text>*/}
          <Signals sig={item} />
          <Text style={[styles.textRes, {width: 80}]}>{item.createdAt.slice(0, 10)}</Text>
          <Text style={[styles.textRes, {width: 80}]}>{item.createdAt.slice(11)}</Text>
          <Text style={[styles.textRes, {width: 50}]}>{item.ppmLimit}</Text>
        </View>
       
      ))
    }
    </>
  )
}

const SignalSummary = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) return null;

  return (
    <>
      <View style={styles.signalSum}>
        <Text style={styles.signalSumTxt}>Summering: </Text>
        {
          signalSummary
          && signalSummary.length > 0
          && signalSummary.map(({ count, signalType },index) => (
            <View key={index} style={styles.symbolFlex}>
              <Text style={styles.signalSumTxt}>{count} </Text>
              <SignalsSum sig={signalType} />
            </View>
          ))
        }
      </View>
    </>
  )
}

const Quixote = ({nr, signals, patient}) => (
  <Document>
    <Page style={styles.body}>
  
      <Image
        cache={false}
        style={styles.imageBac}
        src={insigtBlue}
      />
      <View>
        <Text style={styles.title}>Signalrapport</Text>
      </View>
      
      <View style={styles.info}>
        <Text style={styles.userText}>
          CRAS-ID: {patient.usercode.toUpperCase()}
        </Text>
        <Text style={styles.userText}>
          Planerat slut om: {patient.screeningDaysLeft} dagar
        </Text>
      </View>
    
     <View style={styles.signalSumnr}>
        <Text style={styles.totSignals}>
          Antal signaler: <Text style={styles.bold}>{nr}</Text>
        </Text>
       <SignalSummary signalSummary={patient.signalSummary} />
     </View>
      
      <View style={styles.tableHead}>
        <Text style={[styles.textHead, {width: 50}]}>Signal</Text>
        <Text style={[styles.textHead, {width: 80}]}>Resultat(‰)</Text>
        <Text style={[styles.textHead, {width: 170}]}>Beskrivning</Text>
        <Text style={[styles.textHead, {width: 80}]}>Datum</Text>
        <Text style={[styles.textHead, {width: 80}]}>Tidpunkt</Text>
        <Text style={[styles.textHead, {width: 50}]}>Gränsvärde(‰)</Text>
      </View>
      <Rows signals={signals} />
     
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Sida ${pageNumber}(${totalPages})`
      )} fixed >
        </Text>
      <Text style={styles.email} fixed>
        eSanté AB | info@esante.se 
      </Text>
      <Text style={styles.www} fixed>
        www.esante.se
      </Text>
    </Page>
  </Document>
);

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    color: '#191970',
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 8,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width:22,
    height:22
  },
  imageSymbol: {
    marginVertical: 2,
    width:22,
    height:22
  },
  imageBac: {
    marginHorizontal: 200,
    width:146,
  },
  imageLogo: {
    marginVertical: 15,
    width:210,
    height:100
  },
  textRes: {
    marginLeft: 2,
    padding: 3,
    fontSize: 8,
    //textAlign: 'justify',
    //fontFamily: 'Oswald',
    color: '#191970',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: '#083EA7',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'grey',
  },
  email: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 10,
    right: 0,
    textAlign: 'left',
    color: 'grey',
  },
  www: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 10,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    margin: 5,
    fontSize: 12,
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: '#083EA7',
    marginBottom: 4,
  },
  userInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    fontSize: 7,
  },
  userText: {
    fontSize: 10,
    color: '#191970',
    paddingBottom: 2,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: '#f9f9f9',
    //backgroundColor: 'green',
    alignItems: 'center',
    borderBottom: 1,
    borderBottomColor: 'white',
  },
  textHead: {
    marginLeft: 2,
    padding: 8,
    fontSize: 10,
    fontWeight: "bold",
    //textAlign: 'justify',
    //fontFamily: 'Oswald',
    color: "white",
  },
  textCE: {
    padding: 8,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Oswald'
  },
  totSignals: {
    fontSize: 10,
    //textAlign: 'justify',
    color: '#191970',
  },
  signalSum: {
    display: "flex",
    flexDirection: "row",
    //backgroundColor: 'pink',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 8,
  },
  signalSumTxt: {
      fontSize: 10,
      color: '#191970',
  },
  symbolFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
  },
  signalSumnr: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  bold: {
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 15,
  },
  symImg: {
    width: 15,
    height: 15,
    marginRight: 33,
    marginLeft: 13,
    padding: 3,
  },
  info: {
    marginTop: 13,
    marginBottom: 13
  }
});


export default Quixote
