import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { updateProfile, fetchSelf } from "../redux/user";
import { useHistory } from "react-router-dom";
import styles from './ModuleCSS/Therapistprofile.module.css';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

/*
const Input = ({ label, type = "text", name, register, defaultValue, placeholder, errorMessage }) => (
  <p>   
      <label>{label}</label>
      <input
        type={type}
        name={name}
        ref={register}
        defaultValue={defaultValue}
        placeholder={placeholder} />
      {errorMessage}
  </p>
)
*/
export default function UserProfile(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const user = useSelector(({ user: { self }}) => self);
  const [open, setOpen] = useState(false);
  
  const onSubmit = (data) => {
    console.log(data);

    dispatch(updateProfile(data))
      .then(() => dispatch(fetchSelf()))
      .then((resp) => console.log(resp))
      .then(() => setOpen(true))
      .catch((err) => console.log(err));
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (!user) return null;

  console.log(user);
  //const { firstname, lastname, msisdn: phonenr, email } = user;
  
  //const { firstname, lastname, email } = user;

  return (
    <div className={styles.container}>
      <p style={{fontSize: 24, paddingLeft: 20, marginTop: 20}}>Kunduppgifter</p>
      <div className={styles.txtInfo}>
        <p>Här kan du ändra till dina personuppgifter om du loggat in med ett CRAS startkonto. Du kommer efter det att få ett nytt eget användarnamn på mailen från vår support.</p>
        <p>Kontakta vår support om du har några frågor. Kontaktuppgifter till supporten hittar du <span onClick={() => history.push('/Support')} >här.</span></p>
      </div>
      <div className={styles.profile}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.firstName}>   
                <label>FÖRNAMN</label>
                <input type="text" {...register("firstname", { required: true, maxLength: 20, pattern: /^[A-Za-z]+$/i })} defaultValue={user.firstname} placeholder="Förnamn" />
                {errors.firstname && errors.firstname.type === "required" && <span>Du måste fylla i ett förnamn</span>}
                {errors.firstname && errors.firstname.type === "maxLength" && <span>Förnamnet är för långt!</span>}
                {errors.firstname && errors.firstname.type === "pattern" && <span>Får bara innehålla bokstäver</span>}
          </div>
          <div className={styles.lastName}> 
                <label >EFTERNAMN</label>
                <input type="text" {...register("lastname", { required: true, maxLength: 40 })} defaultValue={user.lastname} placeholder="Efternamn.."></input> 
                {errors.lastname && errors.lastname.type === "required" && <span>Du måste fylla i ett efternamn</span>}
                {errors.lastname && errors.lastname.type === "maxLength" && <span>Efternamnet är för långt!</span>}
            </div>
            <div className={styles.lastName}> 
                <label >MAILADRESS</label>
                <input type="email" {...register("email", { required: "du måste skriva in din email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "ogiltig email adress"
                  } })} defaultValue={user.email} placeholder="Din mailadress...."></input>
                {errors.email && <span>{errors.email && errors.email.message}</span>}
            </div>
            <p></p>

          <button className={styles.btn} type="submit">Spara ändringar</button>
        </form>


        {/*
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="FÖRNAMN"
            placeholder="Ditt förnamn"
            name="firstname"
            register={register({ required: true, maxLength: 20})}
            defaultValue={firstname}
            errorMessage={errors.firstname && <span>Du måste skriva in Förnamn</span>}
          />

          <Input
            label="EFTERNAMN"
            placeholder="Ditt efternamn"
            name="lastname"
            register={register({ required: true, maxLength: 20})}
            defaultValue={lastname}
            errorMessage={errors.lastname && <span>Du måste skriva in Efternamn</span>}
          />

           <Input
            label="TELEFONNUMMER"
            placeholder="Ditt telefonnummer"
            name="phonenr"
            register={register}
            defaultValue={phonenr}
          />

          <Input
            label="Mailadress"
            placeholder="Din mailadress.."
            name="email"
            register={register({
                required: "du måste skriva in din email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "ogiltig email adress"
                }
            })}
            defaultValue={email}
            errorMessage={errors.email && <span>{errors.email && errors.email.message}</span>}
          />
          */}
        
      </div>
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                <Alert severity="success">
                    <AlertTitle>Vi har tagit imot era uppgifter:</AlertTitle>
                    <p>Ni kommer få ett nytt användarnam från oss på mailadresse ni uppgav:</p>
                    <h3>{user.email}</h3>
                    <p>/Med vänlig hälsning eSante</p> 
                </Alert>
        </Snackbar>
    </div>
  );
}
