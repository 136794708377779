import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import './App.css';

import IdleTimerContainer from './components/IdleTimerContainer'
import LoginPage from './pages/LoginPage';
import HeadCras from './components/header';
import {
  PatientOverviewPage,
  MeasurementsPage,
  PatientinformationPage,
  SupportPage,
  TherapistProfilePage,
  ControlPanelPage,
  SchedulePage,
  HomePage,
  ManualsPage,
  DocumentsPage,
  AddPatientPage,
  ShopPage,
  UserProfilePage,
} from './pages/pages';
import SignIn from './pages/SignIn';

import { fetchSelf, fetchClients } from './redux/user';

export default function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user: { loggedIn }}) => loggedIn);

  useEffect(() => {
    if (!loggedIn) return;

    dispatch(fetchSelf())
      .then(() => dispatch(fetchClients()))
      .then(console.log)
      .catch(console.log);
  }, [loggedIn, dispatch]);

  if (!loggedIn) return (<LoginPage />);

  return (
    <div className="App">
      <BrowserRouter>
        <HeadCras /> 
        <IdleTimerContainer />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/controlpanel">
            <ControlPanelPage />
          </Route>
          <Route path="/patientoversikt/:clientID">
            <PatientOverviewPage />
          </Route>
          <Route exact path="/matningar/:clientID">
            <MeasurementsPage />
          </Route>
          <Route exact path="/patientinformation/:clientID">
            <PatientinformationPage />
          </Route>
          <Route exact path="/signin">
            <SignIn />
          </Route>
          <Route exact path="/userprofile">
            <UserProfilePage />
          </Route>
          <Route exact path="/therapistprofile">
            <TherapistProfilePage />
          </Route>
          <Route exact path="/support">
            <SupportPage />
          </Route>
          <Route exact path="/schedule/:clientID">
            <SchedulePage />
          </Route>
          <Route exact path="/manuals">
            <ManualsPage />
          </Route>
          <Route exact path="/documents">
            <DocumentsPage />
          </Route>
          <Route exact path="/addpatient">
            <AddPatientPage />
          </Route>
          <Route exact path="/shop">
            <ShopPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
