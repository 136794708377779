import React from 'react'
import styles from './PatientOverview.module.css'
//import green from '../../svg/Green.svg'
import red from '../../svg/Red.svg'
//import yellow from '../../svg/Yellow.svg'
import signalUnderPpmLimit from '../../images/signal-underPpmLimit.png'
import signalMissedScheduleItem from '../../images/signal-missedScheduleItem.png'
import signalAbovePpmLimit from '../../images/signal-abovePpmLimit.png'

const Signals = ({ sig }) => {
    const { signalType: sigType, readAt } = sig;
    let symbol, sigTypeText, styleReadAt;

    switch (sigType) {
        case "underPpmLimit":
            //symbol = green;
            symbol = signalUnderPpmLimit;
            sigTypeText = 'Utandningstest inom gränsvärdet';
            break;
        case "abovePpmLimit":
            //symbol = red;
            symbol = signalAbovePpmLimit;
            sigTypeText = 'Överskridet gränsvärde';
            break;
        case "missedScheduleItem":
            //symbol = yellow;
            symbol = signalMissedScheduleItem;
            sigTypeText = 'Missat utandningstest';
            break;
        default:
            symbol = red;
    }

    if (readAt !== "null") styleReadAt = styles.readAtGray;
         
  return (
            <tr className={styleReadAt}>
                <td>
                    <img className={styles.symbol} src={symbol} alt='symbol' />
                </td>
                <td>{sig.breathRes && `${sig.breathRes.result} ‰`}</td>
                <td>{sigTypeText}</td>
                <td>{sig.createdAt}</td>
                <td>{sig.ppmLimit}‰</td>
                <td className={styles.readAt}> {readAt !== 'null' ? `Läst ${readAt}` : ''}</td>
            </tr>
        )
    }

export default Signals
