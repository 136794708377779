import React from 'react'
import styles from './documents.module.css';
import vector from '../images/Vector.png'
import chefManual from '../InsigtDocuments/CRAS Insight - Chefsmanual.pdf';
import customerAgreement from '../InsigtDocuments/Kundavtal och villkor - CRAS Insight.pdf';
import signsOfAddiction from '../InsigtDocuments/tecken_pa_missbruk.pdf';
import tipsBeforeMeeting from '../InsigtDocuments/tips_infor_medarbetarsamtal.pdf';
import howToReadTheRes from '../InsigtDocuments/Tolkningsguide.pdf';
import factThatCanAffectRes from '../InsigtDocuments/faktorer_som_kan_paverka.pdf';
import alcoholPolicy from '../InsigtDocuments/Mall_Alkoholpoicy_eSante.docx';
import coWorkerTalk from '../InsigtDocuments/Mall_Medarbetaravtal.docx';

const Documents = () => {
    return (
        <div className={styles.container}>
            <h2>Dokument</h2>
            <div className={styles.txtInfo}>
                <p>Här finner du dokument som kan vara värdefulla att ta del av före, under och efter en screeningprocess.
                     Vi rekommenderar att du som minst tittar igenom den korta infon i dokumentet “Chefsmanual”.</p>
            </div>
            <div className={styles.wrapper}>
                
                <div className={styles.item4}>
                    <a href={chefManual} target="_blank" rel="noopener noreferrer">Chefsmanual</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item5}>
                    <a href={customerAgreement} target="_blank" rel="noopener noreferrer">Kundavtal och villkor</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item6}>
                    <a href={signsOfAddiction} target="_blank" rel="noopener noreferrer">Tecken på missbruk</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item7}>
                    <a href={tipsBeforeMeeting} target="_blank" rel="noopener noreferrer">Tips inför medarbetarsamtal</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item8}>
                    <a href={howToReadTheRes} target="_blank" rel="noopener noreferrer">Tolkningsguide</a>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item9}>
                    <a href={factThatCanAffectRes} target="_blank" rel="noopener noreferrer">Faktorer som kan påverka ett utandningstest</a>
                    <img src={vector} alt='vector' />
                </div>
            </div>
            <div className={styles.downloadContainer}>
                <h3>Mallar att ladda ner:</h3>
                <div className={styles.downloadDocs}>
                    <a href={alcoholPolicy} download>Mall - Alkoholpoicy (eSanté)</a>
                </div>
                <div className={styles.downloadDocs}>
                   <a href={coWorkerTalk} download>Mall - Medarbetaravtal</a> 
                </div>
            </div>
        </div>
    )
}

export default Documents
