import React , { useState, useEffect }from 'react'
import styles from './MeasureItem.module.css'
import MeasureGrid from './MeasureGrid'
import axios from 'axios'
import Switch from '@material-ui/core/Switch';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

//const months = ["January","Februari","March","April","May","June","July","August","September","October","November","December"];

const Measurements = () => {
  const { clientID } = useParams();
  const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);

  const [isLoading, setIsLoading] = useState(true)
  const [images, setImages] = useState(true);
  const [breathResults, setBreathResults ] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 15;
  const d = new Date();

  useEffect(() => {
    if (!patient) return;

    setIsLoading(true);

    let source = axios.CancelToken.source()
    axios(`/api/secure/breathalizerResults?targetUser=${patient.id}&limit=${limit}&offset=${offset}&images=${images}`, {cancelToken: source.token})
      .then(({ data }) => {
        console.log('data', data)
        setBreathResults(data)
        setIsLoading(false)  
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('caught cancel');
        } else {
          console.log(err)
        }
        setIsLoading(false)  
      });
        
    return () => {
      console.log('component unmounting code');
      source.cancel()
    }
  }, [offset, limit, images, patient])

  if (!patient) return null;

  const { firstname, lastname, usercode } = patient;
  const { breathalizerResults } = breathResults;

  return (
      <div className={styles.container}>
          <div className={styles.top}>
              <h2>Resultat & bilder</h2>
              <p>{firstname} {lastname} ({usercode.toUpperCase()})</p>
          </div>
          <div className={styles.topSelect}>
             <h3>{ d.getFullYear()}</h3>
              <div className={styles.topright}>
                  <button className={styles.btnMonth} onClick={() => setOffset(Math.max(offset - limit, 0))}>Framåt</button>    
                  <button className={styles.btnMonth} onClick={() => setOffset(Math.min(breathalizerResults - limit, offset + limit))}>Bakåt</button>
                  { images ? <p>Dölj bilder</p> : <p>Visa bilder</p> }
                  <Switch
                      checked={images}
                      onChange={() => setImages(!images)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </div> 
          </div>
          <MeasureGrid isLoading={isLoading} breathRes={breathResults}/>
      </div>
  );
}

export default Measurements
