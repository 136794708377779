import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import styles from './PatientOverview.module.css'
import SignalsTable from './SignalsTable'
import { SignalSum } from '../ControlPanel/tableRow';

// const months = ["Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December"];

function PatientOverview() {
  const { clientID } = useParams();
  const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);

  if (!patient) return null;

  const { firstname, lastname, usercode, signalSummary } = patient;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2>Resultatöversikt</h2>
        <p>{firstname} {lastname} ({usercode.toUpperCase()})</p>
      </div>
      <div className={styles.schema}>
        <div className={styles.head}>
          <div></div>
          <SignalSum signalSummary={signalSummary} />   
        </div>
        <SignalsTable patient={patient} />
      </div>
    </div>
  );
}

export default PatientOverview
