import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
//import { useHistory } from "react-router-dom";

// import { newUser } from '../redux/therapistSlice';

import { tokenRequest, requestToken } from '../redux/user';

//import crasA from '../svg/crasAlkohol.svg';
import insigtBlue from '../images/CRAS_Insight_logo_blue.png';
import styles from './login.module.css';


function LoginPage() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  //const history = useHistory();

  const code = useSelector(({ user: { code }}) => code);
  //const loggedIn = useSelector(({ user: { loggedIn }}) => loggedIn);

  const onError = (err) => {
    // TODO: Handle errors
    console.log('Nu har det blivit fel: ' + err);

    alert('Fel användarnamn / lösenord');
  }
  
  console.log(watch("username")); // watch input value by passing the name of it

  const onSubmit = (data) => {
    dispatch(tokenRequest(data))
      .then(console.log)
      .catch(onError);
  }

  useEffect(() => {
    if (code === null) return;

    dispatch(requestToken(code))
      .then(console.log)
      .catch(onError);
  }, [code, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.form}>
          <div>
            <img src={insigtBlue} alt="logo" width="200" />
          </div>
          <p style={{fontSize: 26}}>Logga in med användarnamn</p>

          <form className={styles.forminput} onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Användarnamn" {...register("username", { required: true, maxLength: 20 })} />
              {errors.username && errors.username.type === "required" && <span>Du måste fylla i detta fällt</span>}
              {errors.username && errors.username.type === "maxLength" && <span>Användarnamnet är för långt!</span>}
            <input type="password" placeholder="Lösenord" {...register("password", { required: true, maxLength: 10 })} />
              {errors.password && errors.password.type === "required" && <span>Du måste fylla i ett lösenord</span>}
              {errors.password && errors.password.type === "maxLength" && <span>Lösenordet är för långt!</span>}
            <button type="submit">Logga in i Insight</button>
          </form>

          <p className={styles.txt}>
            eSantés system är godkända medicintekniska produkter med CE-märkning.
            All data överförs krypterat och behandlas enligt personuppgiftslagen, patientdatalagen och EU:s dataskyddsförordning (GDPR). 
          </p>

          <div className={styles.txt_link}>
            <a href="https://www.esante.se/sekretess-integritet" rel="noreferrer" target="_blank">Läs mer om vår datahantering</a>
            <a style={{paddingLeft: 30}} href="https://esante.se/" rel="noreferrer" target="_blank">Läs mer om eSanté</a> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
