import React from 'react'
import styles from './shop.module.css';
import { useHistory } from "react-router-dom";
//import { useForm } from "react-hook-form";
//import { useDispatch } from 'react-redux';

const Shop = () => {
    const history = useHistory();
    /*
    const onSubmit = data => {
        console.log(data);
        const userData = {
            ...data,
            //userID: parseInt(clientID),
        };
    }
    */
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Beställ alkomätare</h2>
            </div>
            <div className={styles.txtInfo}>
                <p>Ring eller maila vår support för att beställa alkomätare. Om du skickar ett mail, kom ihåg att inkludera den mottagare och adress som mätaren ska skickas till.
                         Kontaktuppgifter till vår support hittar du <span onClick={() => history.push('/Support')}>här.</span></p>
            </div>
            {/*
            <h3>Mottagarens namn</h3>
                <div className={styles.profile}>  
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.form}>
                            <div className={styles.firstName}>   
                                <label>FÖRETAGSNAMN</label>
                                <input type="text" name="CustomerName" ref={register({required: true })} placeholder="Förnamn" />
                            </div>
                            <div className={styles.lastName}> 
                                <label >KONTAKTNAMN</label>
                                <input type="text" name="ContactName" ref={register} placeholder="Efternamn.."></input> 
                            </div>
                            <div className={styles.phonenumber}>
                                <label >GATUADRESS</label>
                                <input type="text" name="address" ref={register} placeholder="Högåsvägen"></input>
                            </div>
                       
                            <div className={styles.altpatientId}>
                                <label>STAD</label>
                                <input type="text" name="city" ref={register} placeholder="Njutånger"></input>
                            </div>
                            <div className={styles.contactPhonenumber}>
                                <div className={styles.flex}>
                                    <label >POSTNUMMER</label>
                                </div>
                                <input  type="text" name="postalCode" ref={register} placeholder="825 93" ></input>
                            </div>
                            <div className={styles.signal}>
                                <div className={styles.flex}>
                                    <label >ANTAL MÄTARE</label>
                                </div>
                                <input  type="number" name="quantity" ref={register} defaultValue={1} placeholder="1" ></input>
                            </div>
                    </div>
                    <button className={styles.btnSend} type="submit">Skicka beställning</button>
                </form>
            </div>
            */}
        </div>
    )
}

export default Shop
