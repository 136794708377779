import React from 'react'
import styles from './ModuleCSS/Support.module.css';
//import { useHistory } from "react-router-dom";
//import mobilePhone from '../images/mobile-phone 1.png'
//import headphones from '../images/headphones-customer-support 1.png'
//import sendEmail from '../images/send-email 1.png'
//import creditCard from '../images/credit-card.png'
//import blow from '../images/Group 186.png'
//import vector from '../images/Vector.png'

function Support() {
    
    return (
        <div className={styles.container}>
                <h2>Hur kan vi hjälpa till?</h2>
                <p>Supportens öppettider är vardagar 08.00 - 17.00</p>
            <div className={styles.wrapper}>
                <div className={styles.support}>
                    <p className={styles.supportTxtHead}>CRAS Supporttelefon</p>
                    <p className={styles.supportTxtCont}>08-23 02 22</p>
                </div>
                <div className={styles.support}>
                    <p className={styles.supportTxtHead}>CRAS Supportmail</p>
                    <a href="mailto:support@esante.se">
                        <p className={styles.supportTxtCont}>support@esante.se</p>
                    </a>
                </div>
                {/*
                <h3>Helpcenter</h3>
                <div className={styles.item4}>
                    <img src={blow} alt='blow' />
                    <p>Alkomätare</p>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item5}>
                <img src={mobilePhone} alt='mob' />
                    <p>Medarbetare</p>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item6}>
                    <img src={creditCard} alt='credit card' />
                    <p className={styles.helpTxt}>Betalningar</p>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item7}>
                    <img src={mobilePhone} alt='mob' />
                    <p>Appen CRAS</p>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item8}>
                    <img src={headphones} alt='headphones' />
                    <p>Teknisk Support</p>
                    <img src={vector} alt='vector' />
                </div>
                <div className={styles.item9}>
                    <img src={sendEmail} alt='send email' />
                    <p>Kontakta eSanté</p>
                    <img src={vector} alt='vector' />
                </div>
                */}
            </div>
            {/*}
            <div className={styles.tecMan}>
                <p onClick={() => history.push('/manuals')}>Tekniska Manualer.</p>
            </div>
            */}
            <div className={styles.policy}>
                <a href="https://www.esante.se/sekretess-integritet" rel="noreferrer" target="_blank">Sekretess- & Integritetspolicy.</a>
            </div>
            <div className={styles.policy}>
                <a href="https://www.esante.se/allm%C3%A4nna-villkor" rel="noreferrer" target="_blank">Allmänna villkor</a>
            </div>
            {/*}
                <h4>Vanliga frågor och svar</h4>
                <select type="text" name="faq1" >
                    <option value="faq1_1">Vad gör jag om min inte alkoholmätaren inte fungerar?</option>
                    <option value="faq1_2">Slå På</option>
                </select>
                <select type="text" name="faq2" >
                    <option value="faq2_1">Hur returnerar jag en Alkoholmätare?</option>
                    <option value="faq2_2">Lägg mätaren å kabeln i förpackningen</option>
                </select>
                <select type="text" name="faq3" >
                    <option value="faq3_1">Hur lägger jag upp en Patient i CRAS?</option>
                    <option value="faq3_2">Klicka på ny patient</option>
                </select>
                <select type="text" name="faq4" >
                    <option value="faq4_1">Hur tar jag bort en Patient från CRAS?</option>
                    <option value="faq4_2">Klicka på avsluta patient</option>
                </select>
            */}
        </div>
    )
}

export default Support
