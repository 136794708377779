import React from 'react'
import styles from './ModuleCSS/AddPatient.module.css';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { updateUser, sendRegSMS } from "../redux/user";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

function Patientinformation() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    //const patient = useSelector(state => state.patient.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const { clientID } = useParams();
    const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);
    
    const onSubmit = data => {
        const userData = {
            ...data,
            userID: parseInt(clientID),
        };
        dispatch(updateUser(userData));
        console.log(userData);
    }

    const sendSMS = () => {
        console.log('sendSMS ' + clientID);
        dispatch(sendRegSMS({userID: parseInt(clientID), message: "Test sms message"}))
        .then(alert('Ett installations sms har skickats till Medarbetaren'))
        .catch(console.log); 
    }

    if (!patient) return null;
    
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.top}>
                    <h2>Information om medarbetaren</h2>
                    <p>{patient.firstname} {patient.lastname} ({patient.usercode.toUpperCase()})</p>
                </div>
                <div className={styles.txtInfo}>
                    <p>Här kan du se och ändra uppgifterna för den medarbetare som undersöks med CRAS Insight.</p>
                    <p>Om du har några frågor, höra av dig till vår support. Kontaktuppgifter till supporten hittar du <span onClick={() => history.push('/Support')} >här.</span></p>
                </div>
                <div className={styles.profile}>  
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.form}>
                            <div className={styles.firstName}>   
                                <label>FÖRNAMN</label>
                                <input type="text" {...register("firstname", { required: true, maxLength: 20, pattern: /^[A-Za-z]+$/i })} defaultValue={patient.firstname} placeholder="Förnamn" />
                                {errors.firstname && errors.firstname.type === "required" && <span>Du måste fylla i ett förnamn</span>}
                                {errors.firstname && errors.firstname.type === "maxLength" && <span>Förnamnet är för långt!</span>}
                                {errors.firstname && errors.firstname.type === "pattern" && <span>Får bara innehålla bokstäver</span>}
                            </div>
                            <div className={styles.lastName}> 
                                <label >EFTERNAMN</label>
                                <input type="text" {...register("lastname", { required: true, maxLength: 40 })} defaultValue={patient.lastname} placeholder="Efternamn.."></input> 
                                {errors.lastname && errors.lastname.type === "required" && <span>Du måste fylla i ett efternamn</span>}
                                {errors.lastname && errors.lastname.type === "maxLength" && <span>Efternamnet är för långt!</span>}
                                
                            </div>
                            <div className={styles.phonenumber}>
                                <label >MEDARBETARENS TELEFONNUMMER</label>
                                <input type="text" {...register("msisdn", { required: true, maxLength: 10, minLength: 8, pattern: /[0-9]{10}/ })} defaultValue={patient.msisdn} placeholder="Telefonnummer.."></input>
                                {errors.msisdn && errors.msisdn.type === "required" && <span>Du måste fylla i ett telefonnummer</span>}
                                {errors.msisdn && errors.msisdn.type === "maxLength" && <span>Telefonnummret har för många sifror. max 10</span>}
                                {errors.msisdn && errors.msisdn.type === "minLength" && <span>Telfonnummret innehåller för få siffror, min 8</span>}
                                {errors.msisdn && errors.msisdn.type === "pattern" && <span>Endast siffror</span>}
                            </div>
                            <div className={styles.patientId}>
                                <div className={styles.flex}>
                                    <label>CRAS-ID</label>
                                    <Tooltip title={<span style={{ fontSize: "12px" }}>ID:t genereras slumpmässigt för att skydda din medarbetares integritet.</span>}>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <input type="text" {...register("usercode")} defaultValue={patient.usercode} placeholder="MANI" readOnly></input>
                            </div>
                            <div className={styles.altpatientId}>
                                <label >EGET-ID</label>
                                <input type="text" {...register("clientid", { maxLength: 12 })} defaultValue={patient.clientID} placeholder="Alternativt ID"></input>
                                {errors.clientid && errors.clientid.type === "maxLength" && <span>ID:et är för långt. max 12</span>}
                            </div>
                            <div className={styles.contactPhonenumber}>
                                <div className={styles.flex}>
                                    <label >TELEFONNUMMER FÖR RESULTATDELNING</label>
                                    <Tooltip title='Medarbetaren kan inte se sin promillehalt utan får endast veta om det är ett rött eller grönt blås via appen. Resultaten kan emellertid delas via SMS till exempelvis en arbetsgivare.'>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <input type="text" {...register("smsrecipients", {
                                    required: true,
                                    maxLength: 10,
                                    pattern: /\d*/ })} defaultValue={patient.smsRecipients} placeholder="Telefonnummer" ></input>
                                {errors.smsrecipients && errors.smsrecipients.type === "required" && <span>Du måste fylla i ett telefonnummer</span>}
                                {errors.smsrecipients && errors.smsrecipients.type === "maxLength" && <span>Telefonnummret har för många sifror. max 10</span>}
                                {errors.smsrecipients && errors.smsrecipients.type === "pattern" && <span>Endast siffror</span>}
          
                            </div>
                            <div className={styles.signal}>
                                <div className={styles.flex}>
                                    <label >DELA RESULTAT VIA SMS VID:</label>
                                    <Tooltip title='Här ser du vilka resultat som ska delas till telefonnumret för resultatdelning. Vi rekommenderar “Missade och över gränsvärdet”.'>
                                        <InfoOutlinedIcon style={{color: "blue"}}/>
                                    </Tooltip>
                                </div>
                                <select type="text" {...register("smslevel")} defaultValue={patient.smsLevel} >
                                    <option value="">Missade och över gränsvärde</option>
                                    <option value="all">Alla signaler</option>
                                </select>
                            </div>
                        </div>
                    {/*
                    <p style={{fontSize: 24, marginTop: 20}}>Behandlingsinformation</p>
                    <div className={styles.formbottom}>
                            <p className={styles.treatmentmethod}>
                                <label>BEHANDLINGSMETOD</label>
                                <select type="text" name="method" ref={register}>
                                    <option value="scaledown">Nykterhetskontroll</option>
                                    <option value="">Kontrollerat drickande</option>
                                </select>
                            </p>
                            <p className={styles.threshold}>
                                <label>TRÖSKELVÄRDE</label>
                                <select type="number" name="threshold" value={patient.ppmLimit} ref={register}>
                                    <option value="0.00">0.00</option>
                                    <option value="0.05">0.05</option>
                                    <option value="0.10">0.10</option>
                                    <option value="0.15">0.15</option>
                                    <option value="0.20">0.20</option>
                                    <option value="0.25">0.25</option>
                                    <option value="0.30">0.30</option>
                                    <option value="0.40">0.40</option>
                                    <option value="0.50">0.50</option>
                                    <option value="0.60">0.60</option>
                                    <option value="0.70">0.70</option>
                                    <option value="0.80">0.80</option>
                                    <option value="0.90">0.90</option>
                                    <option value="1.00">1.00</option>
                                    <option value="1.10">1.10</option>
                                    <option value="1.20">1.20</option>
                                    <option value="1.30">1.30</option>
                                    <option value="1.40">1.40</option>
                                    <option value="1.50">1.50</option>
                                </select>
                            </p>
                            <div className={styles.showResult}>
                                <label>VISA MÄTRESULTAT FÖR PATIENTEN?</label>
                                <div className={styles.radio}>
                                    <input type="radio" id="no" name="showresult" value="false" ref={register} ></input>
                                    <label htmlFor="no">Nej</label>
                                    <input type="radio" id="yes" name="showresult" value="true" ref={register} ></input>
                                    <label htmlFor="yes">Ja</label>
                                </div>
                            </div>  
                            <button className={styles.btnSaveC} type="submit">Spara ändringar</button>
                    </div>
                    */}
                    <button className={styles.btnSaveC} type="submit">Spara ändringar</button>
                    </form>
                    <button className={styles.btnSaveC} onClick={sendSMS}>Skicka ett aktiverings SMS en gång till</button>
                <div className={styles.smsInfo}>
                    {/*
                    <p className={styles.smsInfoTxt}> Medarbetaren kan inte se sin promillehalt utan får endast veta om det är ett rött eller grönt blås via appen.
                         Resultaten kan emellertid delas via SMS till exempelvis en arbetsgivare.
                    </p>
                */}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Patientinformation
