import React from 'react'
import styles from './ControlPanel.module.css'
import bin from '../../svg/bin 1.svg'
import Tooltip from '@material-ui/core/Tooltip';


const EndedTable = () => {

    return (
        <>
        <table className={styles.endedTable}>
            <thead>
                    <tr>
                        <th>Medarbetare</th>
                        <th>ID</th>
                        <th>Avslutad Screening</th>
                        <th></th>
                    </tr>
            </thead>
            <tbody>
                    <tr>
                        <td>CRASDemo Nilsson</td>
                        <td>MDFR</td>
                        <td className={styles.endDate}>2019.04.23</td>
                        <Tooltip title="Kontakta supporten om du vill Återaktivera eller ta bort en medarbetare">
                            <td className={styles.bin}>
                                <button className={styles.btnReactivate}>Återaktivera</button>
                                <img src={bin} alt='Bin' />
                            </td>
                        </Tooltip>
                    </tr>
                    <tr>
                        <td>CRASDemo Chang</td>
                        <td>FFGR</td>
                        <td className={styles.endDate}>2019.06.28</td>
                        <Tooltip title="Kontakta supporten om du vill Återaktivera eller ta bort en medarbetare">
                            <td className={styles.bin}>
                                <button className={styles.btnReactivate}>Återaktivera</button>
                                <img src={bin} alt='Bin' />
                            </td>
                        </Tooltip>
                    </tr>
                   
                </tbody>
            </table>
    </>
    )
}

export default EndedTable
