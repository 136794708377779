import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
//import { compose, combineReducers } from 'redux';
//import persistState from 'redux-localstorage'
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';

import userReducer from '../redux/user';
import therapistReducer from '../redux/therapistSlice';
import patientReducer from '../redux/patientSlice';
import therapistUsersReducer from '../redux/therapistUsersSlice';

const reducer = combineReducers({
  user: userReducer,
  therapist: therapistReducer,
  patient: patientReducer,
  therapistUsers: therapistUsersReducer,
});
/*
const enhancers = [
  compose(
    persistState(),
  )
];
*/

const crasapi = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: '/api/',
  responseType: 'json'
});

export default configureStore({
  reducer,
  // enhancers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosMiddleware(crasapi)),
});
