import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  code: null,
  token: null,
  self: null,
  clients: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      console.log('LOGOUT');
      state.loggedIn = false;
      state.code = null;
      state.token = null;
      state.self = null;
      state.clients = null;
    },
    tokenRequest_SUCCESS: (state, { payload }) => {
      const { data: { message: code }} = payload;

      state.code = code;
    },
    requestToken_SUCCESS: (state, { payload }) => {
      const { data: { message: token }} = payload;

      state.token = token;
      state.loggedIn = true;
      state.code = null;
    },
    fetchSelf_SUCCESS: (state, { payload }) => {
      const { data: { user }} = payload;

      console.log('fetchSelf', user);
      state.self = user;
    },
    fetchClients_SUCCESS: (state, { payload }) => {
      const { data: { users } } = payload;

      state.clients = users;
    },
    newSchedule_SUCCESS: (state, { payload }) => {
      const { data: { userId } } = payload;
      console.log(userId);
      //state.ktm = userId;
    },
  }
});

export const tokenRequest = createAction(
  'user/tokenRequest',
  ({ username, password}) => ({
    type: 'tokenRequest',
    payload: {
      request: {
        url: '/tokenrequests',
        method: 'POST',
        auth: {
          username,
          password,
        }
      }
    }
  })
);

export const requestToken = createAction(
  'user/requestToken',
  (code) => ({
    type: 'requestToken',
    payload: {
      request: {
        url: '/tokens',
        method: 'POST',
        headers: { 'Authorization': `OTP ${code}` }
      }
    }
  })
);

export const fetchSelf = createAction(
  'user/fetchSelf',
  () => ({
    type: 'fetchSelf',
    payload: {
      request: {
        url: '/self',
        method: 'GET',
      }
    }
  })
);

export const fetchClients = createAction(
  'user/fetchClients',
  () => ({
    type: 'fetchClients',
    payload: {
      request: {
        url: '/user?userid=0',
        method: 'GET',
      }
    }
  })
);

export const updateProfile = createAction(
  'user/updateProfile',
  (data) => ({
    type: 'updateProfile',
    payload: {
      request: {
        url: '/therapist/updatetherapist',
        method: 'POST',
        data,
      }
    }
  })
);

export const newUser = createAction(
  'user/newUser',
  (data) => ({
    type: 'newUser',
    payload: {
      request: {
        url: '/user/newuser',
        method: 'POST',
        data,
      }
    }
  })
);

export const updateUser = createAction(
  'user/updateUser',
  (data) => ({
    type: 'updateUser',
    payload: {
      request: {
        url: '/user/updateuser',
        method: 'POST',
        data,
      }
    }
  })
);

export const newSchedule = createAction(
  'user/newSchedule',
  (data) => ({
    type: 'newSchedule',
    payload: {
      request: {
        url: '/schedule/newschedule',
        method: 'POST',
        data,
      }
    }
  })
);

export const sendRegSMS = createAction(
  'sms/sendRegSMS',
  (data) => ({
    type: 'sendRegSMS',
    payload: {
      request: {
        url: '/sms/sendRegSMS',
        method: 'POST',
        data,
      }
    }
  })
);

const { reducer, actions } = userSlice;

export const { logout } = actions;
export default reducer;
